export default {
  signin: {
    title: 'Personaliza y emite credenciales verificables para tu comunidad',
    button: 'Inicia sesión con MyWalliD wallet',
    signup: ['¿No tienes una MyWalliD wallet?', 'Crea una'],
  },
  signup: {
    step: ['Paso', 'de'],
    steps: [
      {
        title: 'Crea una MyWalliD wallet',
        subtitle:
          'Crea y gestiona tu cuenta con MyWalliD wallet. Instala el complemento de la wallet y mantén tus datos siempre privados en tu navegador.',
        button: 'Instalar complemento',
        signup: ['¿Ya tienes una MyWalliD wallet? ', 'Inicia sesión'],
      },
      {
        title: 'Configura tu MyWalliD wallet',
        subtitle:
          'Sigue los pasos en el complemento de MyWalliD a la derecha y completa la configuración de tu wallet.',
        button: 'Crear wallet',
        signup: ['¿Ya tienes una wallet digital? ', 'Inicia sesión'],
      },
      {
        title: 'Iniciar sesión',
        subtitle:
          'Inicia sesión con MyWalliD wallet y mantén tus datos siempre privados.',
      },
    ],
  },
  successModal: {
    design: {
      title: 'Diseño creado exitosamente',
      text: 'Este {designType} fue añadido exitosamente a tu portafolio. Accede a ellos y emítelos desde el panel de control',
    },
  },
  dashboard: {
    title: 'Panel de Control',
    subtitle: 'Tu portafolio de credenciales',
    emptyTemplate: 'Crear una nueva credencial',
    counters: {
      pending: 'Aprobaciones pendientes',
      revoked: 'Credenciales revocadas',
      templates: 'Plantillas creadas',
      issued: 'Credenciales emitidas',
      approved: 'Credenciales aprobadas',
    },
  },
  profile: {
    title: 'Perfil',
    linkBlockChain: 'Verificar el Emisor de Credenciales en la Blockchain',
    issuerID: 'ID del Emisor de Credenciales en la Blockchain',
    admins: {
      title: 'Todos los administradores',
      tabs: ['Todos los administradores', 'Maestros', 'Gerentes'],
      menu: ['Añadir nuevo Administrador'],
    },
    credentials: {
      title: 'Plantillas de credenciales',
      menu: ['Añadir nueva plantilla', 'Eliminar plantilla'],
      empty: [
        'Parece que no tienes ninguna plantilla de credencial.',
        'Añade una plantilla de credencial',
        '¡ahora!',
      ],
    },
  },
  issue: {
    pageTitle: 'Credenciales Emitidas',
    title: 'Emitir Credenciales',
    new: 'Emitir Credenciales',
    labelUpload: 'Subir Imagen',
    buttonUpload: 'Subir',
    menu: ['Emitir credenciales manualmente', 'Emitir credenciales en masa'],
    wallet: '¿La persona acredidata tiene una MyWalliD wallet?',
    radio: ['Sí', 'No'],
    walletField: 'Dirección de MyWalliD de la persona acreditada',
    emailField: 'Dirección de correo electrónico de la persona acreditada',
    emailHint:
      'Se generará automáticamente un correo electrónico y se enviará para que la persona acreditada pueda configurar una MyWalliD wallet y recibir esta credencial.',
    emailError: 'Por favor, introduce un correo electrónico válido',
    button: 'Siguiente',
    IssueCredentials: 'Emitir credenciales manualmente',
    IssueInBulk: 'Emitir credenciales en masa',
  },
  inBulkModal: {
    title: 'Emitir Credenciales en masa',
    text1:
      'Sube un archivo con una tabla que contenga toda la información de los miembros para esta credencial',
    text2:
      'Asegúrate de que tu archivo tenga los mismos atributos y en el mismo orden que definiste en la plantilla de la credencial en la plataforma y que estén ordenados de izquierda a derecha en la misma línea en la tabla.',
    text3:
      'Confirma los datos en las credenciales a emitir y envíalas a todos los miembros mostrados abajo.',
    upload: ['Arrastra y suelta o ', 'sube ', ' tu hoja de cálculo'],
    buttonNext: 'Siguiente',
    buttonIssue: 'Emitir credenciales',
    buttonDownload: 'Descargar plantilla de Excel',
    error:
      'Hay atributos dentro del documento de Excel que no coinciden con los atributos de tu plantilla de credencial.',
    errorFile:
      'No aceptamos este formato de archivo. Por favor, sube un formato de hoja de cálculo CSV.',
    hint: 'Solo aceptamos formato de hoja de cálculo CSV',
  },

  // NEW TEXT
  credentialDesign: {
    title: 'Crear una nueva credencial',
    subtitle: ['Elige un diseño preestablecido', 'Crea tu diseño'],
    text: [
      'Sed ut de voluptate et inter mediocrem animadversionem atque admonitionem.',
      'In oculis quidem faciunt, ut summo bono, dolorem aspernari ut ita ruant itaque.',
    ],
    buttons: ['Elegir diseño preestablecido', 'Crear diseño'],
    uploadLogo: 'Subir Logo',
    addDesignButton: 'Añadir diseño',
    preset: {
      ChoosePresetTemplate: 'Elige un diseño preestablecido',
      subtitle: 'Selecciona un tipo de credencial:',
      types: ['Insignia', 'Certificado', 'Tarjeta'],
      label: {
        Badge: 'Nombra esta insignia:',
        Certificate: 'Nombra este certificado:',
        Card: 'Nombra esta tarjeta:',
      },
      photoLabel: {
        Badge: 'Sube el logo que quieras que aparezca en la insignia',
        Certificate: 'Sube el logo que quieras que aparezca en el certificado',
        Card: 'Sube el logo que quieras que aparezca en la tarjeta',
      },
    },
  },
  // END NEW TEXT
  createCertModal: {
    title: 'Añadir nueva plantilla de credencial',
    upload: [
      'Subir Logo del Emisor de Credenciales',
      'Tamaño máx. de archivo: 5 MB',
      'Solo JPG o PNG',
    ],
    errorUrlPhoto: 'Por favor, sube una foto',
    uploadButton: 'Subir foto',
    maxImages:
      'No es posible añadir más de 3 imágenes a esta plantilla de credencial',
    name: 'Nombre de la plantilla de credencial',
    placeholder: 'Escribe un nombre para la plantilla',
    errorCertName: 'Por favor, añade un nombre para tu plantilla de credencial',
    radio: [
      'Tipo de plantilla de credencial',
      'Lado frontal',
      'Frente y dorso',
    ],
    front: 'Lado frontal',
    back: 'Dorso',
    frontSide: {
      add: ['Añade atributos a tu credencial:', 'Añadir'],
      dropdown: ['Atributo', 'Tipo de entrada'],
      placeholder: [
        'Selecciona un atributo',
        'Selecciona un tipo de entrada',
        'Escribe un atributo',
      ],
      mandatory: 'Obligatorio',
    },
    signatures: {
      add: ['Añade firmas a tu plantilla de credencial:', 'Añadir'],
      dropdown: ['Nombre', 'Rol', 'Tipo de firma'],
      hint: 'Solo escribe el nombre y apellido',
      placeholder: ['Escribe un nombre', 'Escribe un rol'],
      maxSigs:
        'No es posible añadir más de 2 firmas a esta plantilla de credencial',
      error: 'Por favor, añade al menos una firma',
    },
    backSide: {
      type: 'Tipo de atributo',
      add: ['Añade columnas a tu tabla: ', 'Añadir'],
      dropdown: ['Nombre de columna', 'Tipo', 'Tipo de entrada'],
      placeholder: [
        'Selecciona un nombre de columna',
        'Selecciona un tipo de entrada',
        'Escribe un nombre de columna',
      ],
      maxColumns:
        'No es posible añadir más de 3 columnas a esta plantilla de credencial',
    },
    errorModule: 'Por favor, añade un módulo',
    errorType: 'Por favor, añade un tipo',
    errorAttribute: 'Por favor, añade un atributo',
    errorInput: 'Por favor, añade un tipo de entrada al atributo',
    publicField: ['Atributo Público', 'Selecciona atributo público'],
    errorPublicField:
      'Por favor, elige un atributo público. No será visible en ningún otro lugar pero te ayudará a identificar esta credencial entre todas las demás en tu tabla de credenciales',
    hint: 'El atributo público será visible para cualquiera que acceda a la plataforma y actuará como el identificador de esta credencial.',
    card: 'Confirma el diseño de la credencial y si los atributos están correctamente configurados.',
    hintCard:
      'Una vez que crees esta plantilla de credencial, no podrás volver y editarla.',
  },
  createCAModal: {
    title: 'Bienvenido a WalliD para Emisores de Credenciales',
    subtitle: 'Crea tu entidad Emisora de Credenciales en la Blockchain',
    caName:
      'Proporciona el nombre de tu institución o comunidad para generar un ID único en la blockchain:',
    email: 'Correo electrónico',
    hint: 'Asegúrate de que sea un nombre públicamente reconocido ya que se asociará a cada credencial que emitas.',
    errorCaName:
      'Por favor, añade un nombre a esta entidad Emisora de Credenciales',
    errorEmail: 'Por favor, añade un correo electrónico válido',
  },
  cancelModal: {
    title: 'Cancelar Credencial',
    text: '¿Estás seguro de que quieres cancelar esta credencial? <br> No podrás deshacer esta acción.',
  },
  revokeModal: {
    title: 'Revocar Credencial',
    text: '¿Estás seguro de que quieres revocar esta credencial? <br> No podrás deshacer esta acción.',
    button: 'Revocar Credencial',
  },
  viewCredential: {
    tabs: ['Todas las credenciales', 'Pendientes', 'Aprobadas', 'Revocadas'],
    approved: 'Parece que aún no tienes ninguna credencial aprobada.',
    pending: 'Sin aprobaciones pendientes.',
    revoked: 'Sin credenciales revocadas.',
    noCredentials: [
      'Parece que aún no has emitido ninguna credencial.',
      'Emite tu primera credencial',
      '¡ahora!',
    ],
    modal: {
      title: 'Ver credencial',
    },
  },
  pendingTasks: {
    title: 'Aprobaciones Pendientes',
    tabs: ['Credenciales Pendientes ', 'Aprobadas '],
    emptyTable: 'Parece que no tienes ninguna aprobación pendiente por ahora',
  },
  header: {
    dashboard: 'Panel de Control',
    issuing: 'Perfil del Emisor de Credenciales',
    settings: 'Configuraciones',
  },
  footer: {
    language: 'Idioma:',
    contacts: 'Contáctanos',
  },
  table: {
    search: 'Buscar por nombre o ID de Credencial',
    searchAdmin: 'Buscar por nombre o rol',
    roles: {
      MASTER: 'Maestro',
      MANAGER: 'Gerente',
    },
    noData: 'No se encontraron registros coincidentes',
    menu: {
      view: 'Ver credencial',
      download: 'Descargar credencial',
      approve: 'Aprobar credencial',
      revoke: 'Revocar credencial',
      cancel: 'Cancelar credencial',
      all: 'Aprobar Todo',
    },
  },
  status: {
    waiting_wallet: 'Esperando wallet',
    pending_approval: 'Aprobación pendiente',
    active: 'Aprobado',
    revoke: 'Revocado',
    pending: 'Pendiente',
    activeA: 'Activo',
  },
  links: {
    copyright: '©2021 Todos los Derechos Reservados. WalliD®',
    terms: 'Términos y condiciones',
    privacy: 'Política de Privacidad',
  },
  button: {
    createCA: 'Crear',
    add: 'Añadir otra fila',
    next: 'Siguiente',
    back: 'Atrás',
    continue: 'Continuar',
    create: 'Añadir plantilla',
    issue: 'Emitir credencial',
    confirm: 'Confirmar',
    cancel: 'Cancelar Credencial',
    download: 'Descargar plantilla de Excel',
  },
  tooltips: [
    {
      title: 'Panel de Control',
      text: 'Una visión general de la actividad de la entidad Emisora de Credenciales. Accede y completa todas las tareas pendientes desde este panel de control.',
    },
    {
      title: 'Perfil del Emisor de Credenciales',
      text: 'Gestiona las plantillas de credenciales, administradores y permisos del Emisor de Credenciales.',
    },
    {
      title: 'Configuraciones',
      text: 'Accede a tu cuenta y verifica los permisos de esta entidad Emisora de Credenciales',
    },
    {
      title: 'Pestaña de aprobaciones pendientes',
      text: 'Gestiona tareas rápidas y pendientes como aprobar o revocar credenciales emitidas.',
    },
    {
      title: 'Plantillas de credenciales',
      text: 'Accede y crea plantillas de credenciales para esta entidad Emisora de Credenciales.',
    },
    {
      title: 'Cerrar sesión',
      text: 'Ve al complemento de MyWalliD wallet a través del ícono en tu navegador para cerrar sesión.',
    },
  ],
  errorMsg: 'Ha ocurrido un error:',
  editProfile: {
    title: 'Editar detalles',
    save: 'Guardar Cambios',
  },
  modals: {
    confirm: {
      title: 'Confirmar creación de plantilla',
      text: '¿Estás seguro de los atributos y la apariencia de la credencial que diseñaste? Una vez que crees esta plantilla no podrás volver y editarla.',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
    },
    storing: {
      title: 'Emitiendo credenciales',
      text: 'Espera un momento mientras estamos emitiendo tus credenciales',
    },
    credentials: {
      title: 'Credenciales enviadas exitosamente',
      text: 'Tus miembros han recibido un correo electrónico con la credencial emitida y ahora configurarán una MyWalliD wallet y la almacenarán en ella. Una vez que ese proceso se complete, serás notificado para aprobar la emisión y podrán usarla en cualquier lugar en línea.',
    },
    email: {
      title: 'Credencial emitida exitosamente!',
      text: 'El usuario destinatario ha recibido la credencial en su bandeja de entrada del correo electrónico. Una vez que la reclame, serás notificado para aprobarla.',
    },
    wallet: {
      title: 'Credencial emitida exitosamente',
      text: 'La credencial fue emitida exitosamente a la MyWalliD wallet de tu miembro.',
    },
    credential: {
      title: 'Credencial enviada exitosamente',
      text: 'Tus miembros han recibido un correo electrónico con la credencial emitida y ahora configurarán una MyWalliD wallet y la almacenarán en ella. Una vez que ese proceso se complete, serás notificado para aprobar la emisión y podrán usarla en cualquier lugar en línea.',
    },
    successCreateTemplate: {
      title: 'Plantilla creada exitosamente',
      text: 'Tu plantilla de credencial fue creada exitosamente. Ahora estás listo para comenzar a emitir credenciales en tu página de panel de control.',
    },
  },
  tour: {
    title: [
      'Panel de Control',
      'Perfil del Emisor de Credenciales',
      // 'Configuraciones',
      'Pestaña de aprobaciones pendientes',
      'Plantillas de credenciales',
      'Cerrar sesión',
    ],
    text: [
      'Una visión general sobre la actividad de tu Entidad Emisora de Credenciales. Accede y completa todas las tareas pendientes desde este panel de control.',
      'Gestiona tus plantillas de Entidad Emisora de Credenciales, administradores y permisos.',
      // 'Accede a tu cuenta y verifica tus permisos para esta Entidad Emisora de Credenciales.',
      'Gestiona tus tareas rápidas y pendientes como aprobar o revocar credenciales emitidas.',
      'Accede y crea plantillas de credenciales para tu Entidad Emisora de Credenciales.',
      'Ve al complemento de MyWalliD wallet a través del ícono en tu navegador para cerrar sesión.',
    ],
    skip: 'omitir tour',
    next: 'siguiente',
    finish: 'finalizar',
  },
  tabs: {
    Templates: 'Plantillas',
    Attributes: 'Atributos',
    Images: 'Imágenes',
    Signatures: 'Firmas',
  },
  canvas: {
    clearPage: 'Limpiar página',
    deletePage: 'Eliminar página',
    addBackside: 'Añadir reverso',
  },
  page: {
    delete: 'Eliminar',
    edit: 'Editar',
    qrcode:
      'Se generará un código QR para <br> cada credencial emitida por ti para <br> que pueda ser verificada por cualquier tercero <br> en formato PDF.',
    qrcodePalceholder: 'VERIFICAR AQUÍ',
  },
  templates: {
    title: 'ELIGE UN PREESTABLECIDO',
    uploadDesign: 'SUBE TU DISEÑO',
    textLine1:
      'Tu diseño debe ajustarse a las dimensiones de una hoja de papel A4 vertical',
    textLine2:
      'El texto que cambiará de una credencial a otra no debe estar en el diseño subido.',
    textLine3:
      'Después de subir tu diseño, puedes usar la pestaña de Texto para insertar cajas de texto dinámicas que cambiarán de una credencial a otra',
    buttonFront: 'Lado frontal',
    buttonBack: 'Reverso',
  },
  attributes: {
    title: 'AÑADIR ATRIBUTOS',
    textLine1:
      'Clase de atributo - entradas con este formato se imprimirán en cada credencial emitida para esta plantilla.',
    textLine2:
      '[CAMPODEATRIBUTO] - entradas con este formato (sin corchetes y en mayúsculas) pueden cambiarse para cada credencial emitida.',
    textLine3: 'Ej: Curso - [NOMBREDELCURSO]',
    buttonAdd: 'Añadir Atributo',
    added: 'Atributos añadidos',
    attributeName: 'Nombre del atributo',
    mandatory: 'Obligatorio',
    tooltip:
      'Los campos de texto obligatorios deberán ser rellenados al emitir una credencial.',
    noDynamicAttr:
      'No has añadido ningún atributo a esta plantilla. Para continuar y luego emitir esta credencial necesitas añadir al menos un atributo',
  },
  images: {
    title: 'SUBIR IMÁGENES',
    buttonUpload: 'Subir imágenes',
    uploaded: 'Tus imágenes subidas',
  },
  signatures: {
    title: 'AÑADIR FIRMAS',
    buttonAddSignature: 'Añadir firma de texto',
    titleImage: 'SUBIR IMAGEN CON FIRMA',
    buttonUpload: 'Subir imagen',
    uploaded: 'Tus imágenes de firmas subidas',
  },
};
