const state = {
  step: -1,
  stepTemplateEditor: -1,
};
const mutations = {
  setStep: (state, value) => {
    state.step = value;
  },
  setTemplateEditorStep: (state, value) => {
    state.stepTemplateEditor = value;
  },
};
const actions = {};

const getters = {
  step: (state) => state.step,
  stepTemplateEditor: (state) => state.stepTemplateEditor,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
