import html2canvas from 'html2canvas';
import { UPLOAD_FILE } from '../store/actions';

import $ from 'jquery';

import store from '../store';

import { layoutRatios } from '@/consts';

import domtoimage from 'dom-to-image';

export { generateImages, getFileFromUrl };

var numTries = 3;

function isCanvasBlank(canvas) {
  //   const context = canvas.getContext('2d');

  //   const pixelBuffer = new Uint32Array(
  //     context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
  //   );

  //   return !pixelBuffer.some((color) => color !== 0);

  return canvas.height == 0 && canvas.width == 0;
}

async function getFileFromUrl(url, name, defaultType = 'image/png') {
  console.log('Get File from URL', url, name);
  const response = await fetch(url);
  const data = await response.blob();

  //check if file has extension and remove # from name

  const fileName = name.includes('.') ? name : `${name}.png`.replace(/#/g, '');

  return new File([data], fileName, {
    type: response.headers.get('content-type') || defaultType,
  });
}

async function imageExport(canvasObj, name) {
  console.log(canvasObj);
  if (canvasObj) {
    return await getFileFromUrl(canvasObj.toDataURL('image/png'), name);
  }
  return;
}

async function generateImg(pages, tid, height, _ratio) {
  console.log('Generate Img', pages, tid, height, _ratio);
  try {
    let ratio = layoutRatios[_ratio];

    console.log('Print Image', ratio);

    let options = {
      height: height,
      width: height * ratio,
      // cacheBust: true, // Fonts not loading cause of this?
      imagePlaceholder:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAADSCAMAAABThmYtAAAAXVB',
    };

    // let imgsDone = 0;
    let imgArray = [];
    var start = Date.now();

    for (let index = 0; index < pages.length; index++) {
      var node = document.querySelector([pages[index]]);
      console.log(node);
      await domtoimage.toPng(node, options).then(async (dataUrl) => {
        console.log('Data URL', dataUrl);
        imgArray[index] = await store
          .dispatch(UPLOAD_FILE, {
            file: await getFileFromUrl(dataUrl, pages[index] + start),
            folder: tid,
          })
          .catch((err) => {
            console.error('oops, something went wrong!', err);
          });
      });
    }

    return imgArray;
  } catch (error) {
    console.error('Error converting PDF, retry', error);
    if (numTries > 1) {
      numTries--;
      generateImg(pages);
    }
    throw error;
  }
}

// function toDataURL(url, callback) {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function() {
//     var reader = new FileReader();
//     reader.onloadend = function() {
//       callback(reader.result);
//     };
//     reader.readAsDataURL(xhr.response);
//   };
//   xhr.open('GET', url + '?_=' + Date.now(), true);
//   xhr.responseType = 'blob';
//   xhr.send();
// }

async function generateImages(pages, tid, width, _ratio) {
  //   toDataURL(this.photoURL, (dataUrl) => {
  //     this.photoURLBase64 = dataUrl;
  //     // toDataURL(this.frontend_props.background_url, (dataUrl) => {
  //     // this.frontend_props.background_url = dataUrl;
  //     window.scroll(0, 0);
  //     //Wait for Vue update
  $('html').addClass('hide-scrollbar');
  console.log('Call generate Img', pages);
  window.scroll(0, 0);

  numTries = 3;
  let res = await generateImg(pages, tid, width, _ratio);

  // this.credentialGeneratedImg = res.img;
  console.log('Generated Images', res);
  $('html').removeClass('hide-scrollbar');

  return res;
  //     // });
  //   });
}
