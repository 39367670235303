/* eslint-disable */

import { consts } from '../consts';

const mixinPlugin = {
  name: 'mixinPlugin',
  components: {},

  computed: {},
  created() {},
  mounted() {},
  methods: {
    async generatePNGfromSVG() {
      var el = document.getElementById('svg-background');
      // let width = 300,
      //   height = 300,
      let svg = new XMLSerializer().serializeToString(el); //convert SVG to string

      let svg64 = window.btoa(svg);
      let url = await this.base64SvgToBase64Png(svg64, 1500);
      async function getFileFromUrl(url, name, defaultType = 'image/png') {
        const response = await fetch(url);
        const data = await response.blob();
        return new File([data], name, {
          type: response.headers.get('content-type') || defaultType,
        });
      }
      const file = await getFileFromUrl(url, 'background' + svg64.slice(0, 16));

      return await this.uploadPhoto(file, 'background');
      // const canvas = new OffscreenCanvas(width, height);
      // const ctx = canvas.getContext("2d");
      // const v = await Canvg.from(ctx, svg);

      // // Render only first frame, ignoring animations and mouse.
      // await v.render();

      // // const blob = await canvas.convertToBlob();
      // const pngUrl = URL.createObjectURL(blob);

      // console.log(pngUrl);
    },

    base64SvgToBase64Png(originalBase64, width) {
      return new Promise((resolve) => {
        let img = document.createElement('img');
        img.onload = function () {
          document.body.appendChild(img);
          let canvas = document.createElement('canvas');
          let ratio = img.clientWidth / img.clientHeight || 1;
          document.body.removeChild(img);
          canvas.width = width;
          canvas.height = width / ratio;
          let ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          try {
            let data = canvas.toDataURL('image/png');
            resolve(data);
          } catch (e) {
            console.error(e);
            resolve(null);
          }
        };
        img.src = 'data:image/svg+xml;base64,' + originalBase64;
      });
    },
    reduceText(text, length = 44, clamp = '...') {
      let splitAt = (index) => (x) => [x.slice(0, index), x.slice(index)];
      if (text.length > length) {
        return [splitAt(length)(text)[0] + clamp, text];
      } else {
        return [text];
      }
    },
    /**
     *
     * Forces scroll into element with @id and a given @offset
     */
    scrollIntoID(id, offset) {
      this.$log.debug('scrollIntoID', id, offset);

      let wrapper = document.getElementById('scroll');

      var parentViewableArea = {
        height: wrapper.clientHeight,
        width: wrapper.clientWidth,
      };
      var parentRect = wrapper.getBoundingClientRect();

      var element = document.getElementById(id);

      var childRect = element.getBoundingClientRect();

      var isViewable =
        childRect.top >= parentRect.top &&
        childRect.top <= parentRect.top + parentViewableArea.height;
      this.$log.debug('offsetPosition', offset);

      // if (!isViewable) {
      this.$log.debug('isViewable', isViewable);

      setTimeout(
        () =>
          wrapper.scrollBy({
            top: offset,
            left: 0,
            behavior: 'smooth',
          }),
        100
      );
      // }
    },

    /**
     *
     * Forces scroll into element with error with @id and a given @offset
     */
    scrollIntoError(id, offset) {
      this.$log.debug('scrollIntoError', id, offset);

      var element = document.getElementById(id);

      let wrapper = document.getElementById('scroll');

      var parentRect = wrapper.getBoundingClientRect();
      // What can you see?
      var parentViewableArea = {
        height: wrapper.clientHeight,
        width: wrapper.clientWidth,
      };

      // Where is the child
      var childRect = element.getBoundingClientRect();
      // Is the child viewable?
      var isViewable =
        childRect.top >= parentRect.top &&
        childRect.top <= parentRect.top + parentViewableArea.height;

      // if you can't see the child try to scroll parent
      if (!isViewable) {
        // scroll by offset relative to parent
        var elementPosition = childRect.top - wrapper.scrollTop;

        var offsetPosition = elementPosition - parentRect.top;
        this.$log.debug('offsetPosition', offsetPosition);

        wrapper.scrollBy({
          top: offsetPosition,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    getEmail(email) {
      var name = email.substring(0, email.lastIndexOf('@'));
      var domain = email.substring(email.lastIndexOf('@') + 1);
      return { name: name, domain: domain };
    },
    isEmailValid(email) {
      return email == '' ? '' : this.reg.test(email) ? true : false;
    },
    checkPageFocus() {
      return document.hasFocus();
    },
    scrollInto(id, offset) {
      this.$log.debug(id, offset);

      this.$nextTick(() => {
        var element = document.getElementById(id);
        var headerOffset = offset;
        var elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        var offsetPosition = elementPosition - headerOffset;
        var behavior = 'smooth';
        window.scrollTo({
          top: offsetPosition,
          behavior: behavior,
        });
      });
    },
    getMethodName() {
      let error = {};
      try {
        throw new Error('');
      } catch (e) {
        error = e;
      }
      // IE9 does not have .stack property
      if (error.stack === undefined) {
        return '';
      }
      let stackTrace = error.stack.split('\n')[3];
      if (/ /.test(stackTrace)) {
        stackTrace = stackTrace.trim().split(' ')[1];
      }
      if (stackTrace && stackTrace.indexOf('.') > -1) {
        stackTrace = stackTrace.split('.')[1];
      }
      return stackTrace;
    },
    debug(a, ...args) {
      if (this) {
        let methodName = this.getMethodName();

        this.$log.debug(methodName + ' | ', a, ...args);
      } else {
        console.log(a, ...args);
      }
    },
    logError(a, ...args) {
      if (this) {
        let methodName = this.getMethodName();
        this.$log.error(methodName + ' | ' + a, ...args);
      } else {
        console.error(a, ...args);
      }
    },
  },
  data() {
    return {
      WAITING_WALLET: consts.WAITING_WALLET,
      PENDING_APPROVAL: consts.PENDING_APPROVAL,
      APPROVED: consts.APPROVED,
      REVOKED: consts.REVOKED,
      ACTIVE: consts.ACTIVE,
      PENDING: consts.PENDING,

      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
};

/* eslint-enable */
export default mixinPlugin;
