// API routes
export const CREATE_CA = '/ca/';
export const UPDATE_CA = '/ca/updateca/';
export const GET_CA = '/ca/getca/';

export const DASHBOARD = '/dashboard';
export const CREATE_TEMPLATE = '/template/';
export const TEMPLATE_ITEM = '/templateItem';
export const ISSUE_USER = '/user';

export const ACCEPT = '/admin/accept';
export const PROFILE = '/admin/profile';

export const APPROVE = '/admin/approve';
export const REVOKE = '/admin/revoke';
export const PREP_SIGN = '/admin/prepsig';
export const UPLOAD_DOC = '/admin/parsefile';
export const IMPORT_FILE = '/admin/importfile';
export const TEMPLATE_SYNC = '/admin/templatesync';

export const TEMPLATE_EDITOR_CREATE = 'TEMPLATE_EDITOR_CREATE';
export const TEMPLATE_ITEM_EDITOR = 'TEMPLATE_ITEM_EDITOR';

//WALLET ACTIONS
export const UNLOCK_WALLET = 'UNLOCK_WALLET';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const GET_TEMPLATE = 'GET_TEMPLATE';

export const WALLID_CONNECT = 'wallid_connect';

// AWS ACTIONS

export const UPLOAD_FILE = 'UPLOAD_FILE';
