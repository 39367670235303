export default {
  header: {
    title: 'Create your design',
    buttonCreate: 'Create',
  },
  tabs: {
    Templates: 'Layouts',
    Attributes: 'Attributes',
    Images: 'Images',
    Signatures: 'Signatures',
    Backgrounds: 'Background',
  },
  canvas: {
    clearPage: 'Clear page',
    deletePage: 'Delete page',
    addBackside: 'Add backside',
  },
  page: {
    delete: 'Delete',
    edit: 'Edit',
    qrcode:
      'A QR code will be generated for <br> each credential issued by you in <br> order to be verified by any third  <br> party in PDF format.',
    qrcodePalceholder: 'VERIFY HERE',
  },
  templates: {
    title: 'CHOOSE A LAYOUT',
    uploadDesign: 'UPLOAD A BACKGROUND',
    textLine1: 'Your design should fit a vertical A4 sheet of paper dimensions',
    textLine2:
      "Text that will change from credential to credential shouldn't be in the uploaded design.",
    textLine3:
      'After uploading your design, you can use the Text tab to insert dynamic text boxes that will change from credential to credential',
    buttonFront: 'Front side',
    buttonBack: 'Back side',
    buttonBadge: 'Upload',
  },
  backgrounds: {
    title: 'CHOOSE A BACKGROUND',
  },
  attributes: {
    title: 'ADD ATTRIBUTES',
    textLine1:
      'Text - inputs with this format will be printed on every credential issued for this template.',
    textLine2:
      '[PLACEHOLDER] - inputs with this format (square brackets and uppercase) can be changed for every credential issued.',
    textLine3: 'Eg: Course - [COURSENAME]',
    buttonAddText: 'Add Text',
    buttonAddPlaceholder: 'Add Placeholder',
    added: 'Added attributes',
    attributeName: 'Attribute name',
    mandatory: 'Mandatory',
    tooltip:
      ' Mandatory text fields will be required to be filled when issuing a credential.',
    noDynamicAttr:
      "You don't have any attributes added to this template. To continue and then issue this credential you need to add at least one attribute",
  },
  images: {
    title: 'UPLOAD IMAGES',
    buttonUpload: 'Upload images',
    uploaded: 'Your uploaded images',
  },
  signatures: {
    title: 'ADD SIGNATURES',
    buttonAddSignature: 'Add text signature',
    titleImage: 'UPLOAD IMAGE WITH SIGNATURE',
    buttonUpload: 'Upload image',
    uploaded: 'Your uploaded signature images',
  },
};
