<template>
  <v-app>
    <app-header />
    <main>
      <v-container class="router-views">
        <transition origin="center" mode="out-in" :duration="250">
          <router-view />
        </transition>
      </v-container>
    </main>
    <app-footer />
  </v-app>
</template>

<script>
import AppHeader from '../layout/AppHeader';
import AppFooter from '../layout/AppFooter';

import { DASHBOARD, PROFILE } from '../store/actions';
import { WALLID_ADDRESS } from '../store/modules/plugin';

import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    AppHeader,
    AppFooter,
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  watch: {
    $route() {
      this.debug('Update Local data on route change');
      this.$store
        .dispatch(WALLID_ADDRESS)
        .then((res) => {
          if (
            res instanceof Error ||
            res == 'ERR_NO_PERMISSION' ||
            res == 'ERR_PLUGIN_LOCKED'
          ) {
            throw new Error({ message: res });
            // this.$router.push('/SignIn');
          } else {
            this.$store.dispatch(PROFILE);
          }
        })
        .then(() => this.$store.dispatch(DASHBOARD))
        .then(() => this.$store.commit('isLoading', false))
        .catch((err) => {
          this.$router.push({
            path: '/SignIn',
            query: { redirect: this.$route.path },
          });
          console.error(err.message);
          // localStorage.removeItem
        });
    },
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`);

    this.$store.commit('isLoading', true);
  },
  mounted() {
    this.debug('Call PROFILE action');
    this.$store
      .dispatch(WALLID_ADDRESS)
      .then((res) => {
        if (
          res instanceof Error ||
          res == 'ERR_NO_PERMISSION' ||
          res == 'ERR_PLUGIN_LOCKED'
        ) {
          this.$router.push('/SignIn');
        } else {
          this.$store.dispatch(PROFILE);
        }
      })
      .then(() => this.$store.dispatch(DASHBOARD))
      .then(() => this.$store.commit('isLoading', false))
      .catch((err) => {
        console.error(err);
        this.$router.push('/SignIn');
        // localStorage.removeItem
      });
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.counters {
  .v-card {
    box-shadow: 0 2px 4px 0 var(--very-light-grey) !important;
  }
  .v-card__text {
    display: flex;
    padding: 34px 30px;
    padding-right: 0;
    p {
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--charcoal-grey);
      margin-left: 20px;
      margin-bottom: 8px;
    }
    span {
      margin-top: 16px;
      margin-left: 20px;
    }
  }
}

.fade-enter-active {
  transition: opacity 0.3s 0s ease-in-out;
}

.fade-leave-active {
  transition: opacity 0.5s 0s ease-in-out;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
