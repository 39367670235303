import { UPLOAD_FILE } from '../actions';

const API_URL = process.env.VUE_APP_API_URL;

const state = {
  LOGOS_FOLDER: 'wallid-template-logos/',
  photos: [],
};
const mutations = {
  photoUpload: (state, value) => {
    state.photos.push({ url: value });
  },
};
const actions = {
  [UPLOAD_FILE]: async (
    { commit, state },
    { file, folder = state.LOGOS_FOLDER }
  ) => {
    console.log('UPLOAD_FILE Action', file, folder);
    try {
      // upload a file to API_URL endpoint
      const formData = new FormData();
      formData.append('file', file);
      const response = await fetch(`${API_URL}/upload/file/${folder}`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      console.log('UPLOAD_FILE Action', data);
      commit('photoUpload', data.url);
      return data.url;
    } catch (err) {
      console.log(err);
    }
  },
};

const getters = {};

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
