<template>
  <v-container class="signin pa-0">
    <v-row>
      <v-col cols="5" class="pt-8 pb-8" style="padding-left: 10vw">
        <v-col cols="12" class="pt-8 pb-10 pl-9">
          <div class="d-flex align-center">
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="../assets/logo-credential-issuers.png"
              transition="scale-transition"
              width="103"
            />
            <div class="beta_logo ml-3">
              <p>BETA</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="pt-16 pb-5 pl-9">
          <h1 class="title_header">
            {{ $t('signin.title') }}
          </h1>
        </v-col>
        <v-col cols="12" class="pt-1 pr-10 pl-9">
          <!-- Remove on click, add again if user clicks 
              outside plugin login and doesnt sign in  -->
          <v-btn
            v-if="showButton"
            text
            @click="unlockPlugin"
            class="advance-btn"
          >
            {{ $t('signin.button') }}
          </v-btn>
        </v-col>
        <v-col cols="12" class="pt-7 pr-16 pb-16 pl-9">
          <p class="normal_text">
            {{ $t('signin.signup[0]') }}
            <a class="links" @click="unlockPlugin">
              {{ $t('signin.signup[1]') }}
            </a>
          </p>
        </v-col>
      </v-col>

      <v-col cols="7" class="pt-0">
        <v-img
          alt="Wallid Background"
          class="shrink mr-2"
          contain
          src="../assets/wallid-background.jpg"
          transition="scale-transition"
          min-width="864"
          min-height="100"
        />
      </v-col>
    </v-row>
    <app-footer />
  </v-container>
</template>

<script>
// import { mapGetters } from "vuex";

import { SIGN_IN } from '../store/actions';

import AppFooter from '../layout/AppFooter';

import Onboarding from '../plugins/onbooarding';

var newOnboarding;

export default {
  name: 'SignIn',
  components: {
    AppFooter,
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`);
    newOnboarding = new Onboarding(window.location.origin);
  },
  destroyed() {
    window.removeEventListener('focus', this.onFocus);
    window.removeEventListener('blur', this.onBlur);
  },
  mounted() {
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('blur', this.onBlur);
    this.$store.commit('isLoading', false);
  },
  computed: {},
  methods: {
    onFocus: function () {
      this.showButton = true;
    },
    onBlur: function () {
      this.showButton = false;
    },
    unlockPlugin() {
      console.log('Sign in');
      this.showButton = false;
      this.$store
        .dispatch(SIGN_IN)
        .then(() => {
          console.log('Sign in stopOnboarding');
          newOnboarding.stopOnboarding();
          this.$router.push('/Dashboard');
        })
        .catch(() => {
          this.$router.push('/SignUp');
        });
    },
  },
  data() {
    return {
      card: undefined,
      showButton: true,
      sideImage: require('../assets/wallid-background.jpg'),
    };
  },
};
</script>

<style lang="scss">
.beta_logo {
  border-radius: 3px;
  background-color: #f84563;
  padding: 2px 7px;
  p {
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
    margin-bottom: 0;
  }
}
.sign_footer {
  .links {
    font-size: 12px !important;
  }
}
</style>
