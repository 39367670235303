/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
// import router from '../router';

//Modules import
import * as modules from './modules';

var abi = require('ethereumjs-abi');

import {
  CREATE_CA,
  UPDATE_CA,
  GET_CA,
  DASHBOARD,
  CREATE_TEMPLATE,
  TEMPLATE_ITEM,
  GET_TEMPLATE,
  ISSUE_USER,
  APPROVE,
  REVOKE,
  PREP_SIGN,
  UPLOAD_DOC,
  IMPORT_FILE,
  TEMPLATE_SYNC,
  ACCEPT,
  PROFILE,
  GET_TEMPLATE_NAME,
} from './actions';

const ROLES = {
  master: 'MASTER',
  manager: 'MANAGER',
};

const BASE_URL = process.env.VUE_APP_API_URL;

//USER STATES
import { consts } from '../consts';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules,
  //initial state
  state: {
    adminEmail: null,
    errorMsg: null,
    isMaster: false,
    caName: null,
    cid: null,
    contractAddress: null,
    photoURL: null,
    photoHandle: null,
    templates: [],
    allPendingUsers: [],
    maxTemplatesCounter: 1,
    maxIssuedCounter: 351,
    allApprovedCounter: 0,
    allAdminsTable: [],
    mastersTable: [],
    totalIssuedCredentials: 0,
    debug: null,
    loading: false,
    isLoading: true,
  },
  getters: {
    state: (state) => state,
    caAddress: (state) => state.contractAddress,
    isMaster: (state) => state.isMaster,
    caName: (state) => state.caName,
    photoURL: (state) => state.photoURL,
    photoFromDB: (state) => state.photoURL,
    cid: (state) => state.cid,
    allPendingUsers: (state) => state.allPendingUsers,
    maxTemplatesCounter: (state) => state.maxTemplatesCounter,
    maxIssuedCounter: (state) => state.maxIssuedCounter,
    templates: (state) => state.templates,
    photoHandle: (state) => state.photoHandle,
    allApprovedCounter: (state) => state.allApprovedCounter,
    totalIssuedCredentials: (state) => state.totalIssuedCredentials,
    allAdminsTable: (state) => state.allAdminsTable,
    mastersTable: (state) => state.mastersTable,
    errorMsg: (state) => state.errorMsg,
    adminEmail: (state) => state.adminEmail,
    loading: (state) => state.loading,
    isLoading: (state) => state.isLoading,
  },
  actions: {
    [PROFILE]: ({ commit, state }) => {
      state.debug('PROFILE ADMIN');
      return new Promise((resolve, reject) => {
        let body = {
          wa: state.plugin.walletAddress,
        };
        state.debug('body', body);
        axios(BASE_URL + PROFILE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        })
          .then((response) => {
            let data = response.data.data;
            state.debug('PROFILE', data);

            if (data) {
              let admin = {
                name: data.username,
                role: data.no_dca ? ROLES.master : ROLES.manager,
                email: data.email,
                status: 'activeA',
              };
              commit('setAdminEmail', data.email);
              if (
                !state.allAdminsTable.some((e) => e.username == admin.username)
              ) {
                commit('pushAdmin', admin);
              }
              if (data.no_dca) {
                commit('pushMaster', admin);
              }

              // if (data.no_dca) {
              //   let balances = data.balances;
              //   // commit("update_governance", balances["createdTemplates"]);
              //   commit('create_template', balances['totalTemplates']);
              //   // commit("revoke_template", balances[2]);
              //   commit('revoke_credential', balances['totalCredentials']);
              // } else {
              let balances = data.balances;
              state.debug('balances', balances);
              if (balances[0]) {
                // commit("update_governance", balances[0].balances[0]);
                commit(
                  'create_template',
                  balances[0].balances['totalTemplates']
                );
                // commit("revoke_template", balances[2]);
                commit(
                  'revoke_credential',
                  balances[0].balances['totalCredentials']
                );
                // }
              }
              resolve(response.status);
            }
          })
          .catch((err) => {
            console.error(err);
            resolve('No Profile');
            // reject(err);
          });
      });
    },
    [ACCEPT]: ({ commit, state }) => {
      state.debug('ACCEPT ADMIN');

      return new Promise((resolve, reject) => {
        let body = {
          invite_id: localStorage.getItem('invite_admin'),
          wa: state.plugin.walletAddress,
        };
        state.debug('body', body);
        axios(BASE_URL + ACCEPT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        })
          .then((response) => {
            state.debug('ACCEPT ADMIN response', response);
            resolve(response.status);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },

    [ISSUE_USER]: ({ commit, state }, { data, tid, email, imgArray }) => {
      state.debug('ISSUE_USER');

      return new Promise((resolve, reject) => {
        let body = {
          cid: state.cid,
          tid: tid,
          wa_admin: state.plugin.walletAddress,
          data: data,
          email: email,
          imgArray,
        };
        axios(BASE_URL + ISSUE_USER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        })
          .then((response) => {
            state.debug('ISSUE_USER', response);
            resolve(response.status);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },

    [UPDATE_CA]: ({ commit, state }, { name, img_url }) => {
      return new Promise((resolve, reject) => {
        // /CA { wallet } => ca_id
        let body = {
          cid: state.cid,
          name: name,
          img_url: img_url,
        };
        axios(BASE_URL + UPDATE_CA, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        })
          .then((response) => {
            state.debug('UPDATE_CA', response);
            let data = response.data;
            commit('setContractAddress', data.message.contract_address);

            commit('setCA_cid', data.message.cid);
            resolve(data.message.cid);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    [CREATE_CA]: ({ commit, state }, { admin_email }) => {
      return new Promise((resolve, reject) => {
        // /CA { wallet } => ca_id
        let body = {
          wa: state.plugin.walletAddress,
          admin_email,
        };
        axios(BASE_URL + CREATE_CA, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        })
          .then((response) => {
            state.debug('CREATE_CA', response);
            let data = response.data;
            commit('setContractAddress', data.message.contract_address);
            commit('setAdminEmail', data.message.admin_email);
            resolve(data.message.cid);
          })
          .catch((err) => {
            if (err.response.status === 500) {
              reject(err.response.data);
            }
            reject(err);
          });
      });
    },

    [DASHBOARD]: ({ commit, dispatch, state }) => {
      state.debug('Action DASHBOARD');

      return new Promise((resolve, reject) => {
        const compare = (a, b) => {
          const A = a.name.toUpperCase();
          const B = b.name.toUpperCase();

          let comparison = 0;
          if (A > B) {
            comparison = 1;
          } else if (A < B) {
            comparison = -1;
          }
          return comparison;
        };

        // This may cause bugs in reloading
        commit('setCID', null);

        axios(BASE_URL + DASHBOARD, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            wa: state.plugin.walletAddress,
          },
        })
          .then((response) => {
            state.debug('DASHBOARD', response);
            let data = response.data.data;
            if (data.cid && data.ca_creator == state.plugin.walletAddress) {
              commit('setMaster', true);
            }

            if (data.img_url && !data.img_url.startsWith('https')) {
              //fallback for filestack old images
              commit(
                'setPhotoURL',
                'https://cdn.filestackcontent.com/' + data.img_url
              );
            } else {
              //this should be the default url img
              commit('setPhotoURL', data.img_url);
            }

            let orderTemplates = [...data.templates];
            orderTemplates.sort(compare);
            let allPendingUsers = [];
            let totalIssuedCredentials = 0;

            orderTemplates.forEach((t) => {
              totalIssuedCredentials += t.users.length;
              t.users.forEach((u) => {
                if (u.status == consts.PENDING_APPROVAL) {
                  let user = u;
                  user.cred_name = t.name;
                  user.cid = t.cid;
                  user.frontend_props = t.frontend_props;
                  allPendingUsers.push(user);
                }
                if (u.status == consts.APPROVED)
                  commit('incAllApprovedCounter');
              });
            });
            state.debug(allPendingUsers);

            commit('setTotalIssuedCredentials', totalIssuedCredentials);
            commit('setAllPendingUsers', allPendingUsers);
            commit('setContractAddress', data.contract_address);
            commit('setTemplates', orderTemplates);
            commit('setCaName', data.ca_name);
            commit('setCID', data.cid);

            resolve({ cid: data.cid, templates: data.templates });
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    [IMPORT_FILE]: ({ commit, state }, { tid, import_data }) => {
      state.debug('IMPORT_FILE call');
      return new Promise((resolve) => {
        var body = {
          cid: state.cid,
          tid: tid,
          wa_admin: state.plugin.walletAddress,
          import_data: import_data,
        };
        axios(BASE_URL + IMPORT_FILE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        })
          .then((response) => {
            state.debug('SUCCESS!! ' + response.status);
            state.debug(response);
            resolve(response);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    },
    [UPLOAD_DOC]: ({ commit, state }, { formData }) => {
      state.debug('UPLOAD_DOC call');
      return new Promise((resolve, reject) => {
        axios
          .post(BASE_URL + UPLOAD_DOC, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            state.debug('SUCCESS!! ' + response.status);
            state.debug(response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CREATE_TEMPLATE]: ({ commit, state }, { name, frontend_props }) => {
      return new Promise((resolve, reject) => {
        var body = {
          cid: state.cid,
          wa: state.plugin.walletAddress,
          name: name,
          frontend_props,
        };

        axios(BASE_URL + CREATE_TEMPLATE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        })
          .then((response) => {
            state.debug(response);
            resolve(response.data);
          })
          .catch((err) => {
            state.debug(err);
            reject(err);
          });
      });
    },
    [GET_CA]: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        var body = {
          cid: state.cid,
          wa_admin: state.plugin.walletAddress,
        };
        axios(BASE_URL + GET_CA, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        })
          .then((response) => {
            state.debug(response);
            resolve(response);
          })
          .catch((err) => {
            state.debug(err);
          });
      });
    },

    [TEMPLATE_SYNC]: ({ commit, state }, { template, contract_address }) => {
      state.debug('Action TEMPLATE_SYNC');
      return new Promise((resolve) => {
        resolve('ok')
      })

      // return new Promise((resolve, reject) => {
      //   window
      //     .wallid('wallet_sign_erc191', [
      //       contract_address, //state.contractAddress,
      //       template.encodedSod,
      //     ])
      //     .then((res) => {
      //       var body = {
      //         cid: state.cid,
      //         tid: template.tid,
      //         wa_admin: state.plugin.walletAddress,
      //         template_sig: res,
      //       };
      //       axios(BASE_URL + TEMPLATE_SYNC, {
      //         method: 'POST',
      //         headers: {
      //           'Content-Type': 'application/json',
      //         },
      //         data: body,
      //       })
      //         .then((response) => {
      //           state.debug(response);
      //           resolve(response);
      //         })
      //         .catch((err) => {
      //           state.debug(err);
      //         });
      //     })
      //     .catch((err) => {
      //       console.error(err);
      //       reject(err);
      //     });
      // });
    },
    [TEMPLATE_ITEM]: ({ commit, state }, { tid, attrs, tables }) => {
      return new Promise((resolve, reject) => {
        var body = {
          cid: state.cid,
          tid: tid,
          wa: state.plugin.walletAddress,
          attrs: attrs,
          tables: tables,
        };
        axios(BASE_URL + TEMPLATE_ITEM, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        })
          .then((response) => {
            state.debug(response);
            resolve(response);
          })
          .catch((err) => {
            state.debug(err);
            reject(err);
          });
      });
    },
    [GET_TEMPLATE]: ({ commit, state }, { tid }) => {
      return new Promise((resolve) => {
        resolve(state.templates.find((e) => e.tid == tid));
      });
    },
    [APPROVE]: ({ commit, state }, { user }) => {
      return new Promise((resolve, reject) => {
        state.debug('Approve', user);
        var body = {
          wa_user: user.wa,
          wa_admin: state.plugin.walletAddress,
          tid: user.tid,
          user_id: user.id,
        };
        axios(BASE_URL + PREP_SIGN, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        }).then((res) => {
          state.debug(res);
          window
            .wallid('wallet_sign_ec', [res.data.data.data_hash])
            .then((res) => {
              var body = {
                id: user.id,
                wa_admin: state.plugin.walletAddress,
                tid: user.tid,
                sign: res,
              };
              axios(BASE_URL + APPROVE, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                data: body,
              })
                .then((response) => {
                  state.debug(response);
                  resolve(response);
                })
                .catch((err) => {
                  console.error(err);
                });
            })
            .catch((err) => {
              console.error(err);
              state.debug('retry with old call');
              if (Object.keys(err).length === 0 && err.constructor === Object) {
                window
                  .wallid('wallet_sign_ec', res.data.data.data_hash)
                  .then((res) => {
                    var body = {
                      id: user.id,
                      wa_admin: state.plugin.walletAddress,
                      tid: user.tid,
                      sign: res,
                    };
                    axios(BASE_URL + APPROVE, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: body,
                    })
                      .then((response) => {
                        state.debug(response);
                        resolve(response);
                      })
                      .catch((err) => {
                        console.error(err);
                        window
                          .wallid('wallet_ec_sign', res.data.data.data_hash)
                          .then((res) => {
                            var body = {
                              id: user.id,
                              wa_admin: state.plugin.walletAddress,
                              tid: user.tid,
                              sign: res,
                            };
                            axios(BASE_URL + APPROVE, {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              data: body,
                            })
                              .then((response) => {
                                state.debug(response);
                                resolve(response);
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          })
                          .catch((err) => {
                            console.error(err);
                            reject(err);
                          });
                      });
                  })
                  .catch((err) => {
                    console.error(err);
                    reject(err);
                  });
              } else {
                reject(err);
              }
            });
        });
      });
    },
    [REVOKE]: ({ commit, state }, { user }) => {
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        state.debug(user);
        var encoded = abi
          .rawEncode(['bytes32'], ['0x' + user.id])
          .toString('hex');

        window
          .wallid('wallet_sign_erc191', [
            state.contractAddress, //state.contractAddress,
            '0x' + encoded,
          ])
          .then((res) => {
            var body = {
              id: user.id,
              wa_admin: state.plugin.walletAddress,
              tid: user.tid,
              revoke_sig: res,
            };

            axios(BASE_URL + REVOKE, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              data: body,
            })
              .then((response) => {
                state.debug(response);
                commit('setLoading', false);
                resolve(response);
              })
              .catch((err) => {
                commit('setLoading', false);
                reject(err);
              });
          });
      });
    },
  },
  mutations: {
    isLoading: (state, value) => {
      state.isLoading = value;
    },
    setLoading: (state, value) => {
      state.loading = value;
    },
    initLogger: (state, func) => {
      state.debug = func;
    },
    clearError: (state) => {
      state.errorMsg = null;
    },
    errorMsg: (state, errorMsg) => {
      state.errorMsg = errorMsg;
    },
    update_governance: (state, value) => {
      state.maxAdmins = value;
    },
    create_template: (state, value) => {
      state.maxTemplatesCounter = value;
    },
    revoke_credential: (state, value) => {
      state.maxIssuedCounter = value;
    },

    pushMaster: (state, value) => {
      state.mastersTable.push(value);
    },
    pushAdmin: (state, value) => {
      state.allAdminsTable.push(value);
    },
    incAllApprovedCounter: (state) => {
      state.allApprovedCounter = state.allApprovedCounter + 1;
    },
    setAdminEmail: (state, value) => {
      state.adminEmail = value;
    },
    setTotalIssuedCredentials: (state, value) => {
      state.totalIssuedCredentials = value;
    },
    setContractAddress: (state, value) => {
      state.contractAddress = value;
    },
    setAllPendingUsers: (state, value) => {
      state.allPendingUsers = value;
    },
    setTemplates: (state, templates) => {
      state.templates = templates;
    },
    setMaster: (state, value) => {
      state.isMaster = value;
    },
    setPhotoHandle: (state, value) => {
      state.photoHandle = value;
    },
    setPhotoURL: (state, value) => {
      state.photoURL = value;
    },
    setCaName: (state, value) => {
      state.caName = value;
    },
    setCID: (state, value) => {
      state.cid = value;
    },
    setCA_cid: (state, cid) => {
      state.cid = cid;
    },
    updateIdentities(state, value) {
      state.identities = value;
    },
  },
});

store.dispatch('initPlugin');

export default store;
