<template>
  <v-tooltip
    attach
    v-model="show"
    :top="direction == 'top'"
    :right="direction == 'right'"
    :bottom="direction == 'bottom'"
    :left="direction == 'left'"
    :direction="direction"
    content-class="product-tour"
  >
    <template v-slot:activator="{ attrs }">
      <div v-bind="attrs">
        <slot> </slot>
      </div>
    </template>

    <v-container v-if="step != -1" class="stepper pa-4 pb-0">
      <v-row no-gutters class="justify-space-between">
        <div class="pointer" :direction="direction">
          <IconTutorialPointer />
        </div>
        <v-col cols="9">
          <p class="step-title">{{ $t(`tour.title[${step}]`) }}</p>
        </v-col>
        <v-col cols="3">
          <div class="current-step">
            <p>{{ step + 1 }}/5</p>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-end">
        <v-col cols="12">
          <p class="step-text">{{ $t(`tour.text[${step}]`) }}</p>
        </v-col>
        <v-col cols="8" class="text-right">
          <v-btn text class="tour-button" v-if="step < 4" @click="skip">
            <p class="skip">{{ $t(`tour.skip`) }}</p>
          </v-btn>
          <v-btn text class="tour-button" @click="next">
            <p class="next">{{ nextTextButton }}</p>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

import IconTutorialPointer from '@/assets/icon-tutorial-pointer';

export default {
  components: {
    IconTutorialPointer,
  },
  methods: {
    skip() {
      console.log('skip');
      this.$store.commit('tour/setStep', -1);
      //   this.$emit('skip');
    },
    next() {
      console.log('next');
      if (this.step == 4) {
        this.$store.commit('tour/setStep', -1);
      } else {
        let nextStep = this.step + 1;
        setTimeout(() => {
          this.$store.commit('tour/setStep', nextStep);
        }, 110);
        this.$store.commit('tour/setStep', -1);
      }
      //   this.$emit('next');
    },
  },
  computed: {
    ...mapGetters('tour', ['step']),
    nextTextButton() {
      console.log('step', this.step);
      if (this.step == 4) {
        return this.$t(`tour.finish`);
      }
      return this.$t(`tour.next`);
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: 'top',
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.v-tooltip.v-tooltip--right.v-tooltip--attached[direction='right'] {
  .v-tooltip__content {
    margin-left: 20px;
  }
}
.product-tour.v-tooltip__content {
  max-width: 254px;
  width: 254px;
  background: white;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 1px 4px 1px var(--very-light-grey);
  pointer-events: all;
  .pointer[direction='top'] {
    position: absolute;
    top: -24px;
    left: 116px;
  }
  .pointer[direction='right'] {
    position: absolute;
    top: calc(50% - 11px);
    left: -14px;
    transform: rotate(-90deg);
  }
  .pointer[direction='bottom'] {
    position: absolute;
    top: -24px;
    left: 116px;
  }
  .pointer[direction='left'] {
    position: absolute;
    top: -24px;
    left: 116px;
  }
  .stepper {
    .step-title {
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--charcoal-grey);
    }
    .step-text {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: var(--charcoal-grey);
    }
    .current-step {
      float: right;
      max-width: 36px;
      border-radius: 4px;
      background-color: var(--pale-blue);
      text-align: center;
      margin-left: auto;
      margin-right: 0;
      padding: 0px 9px;
      p {
        margin-bottom: 0;
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: 2.6px;
        color: var(--teal-blue);
      }
    }
    .tour-button {
      min-width: unset;
      padding: 0;
      padding-left: 16px;
      text-align: right;
      cursor: pointer;
      .v-ripple__container {
        display: none;
      }
      &:hover {
        &::before {
          content: none;
        }
      }
      .p {
        margin-bottom: 0;
      }
      .next,
      .skip {
        font-size: 11px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      .next {
        color: var(--teal-blue);
      }
      .skip {
        color: var(--light-grey-blue);
      }
    }
  }
}
</style>
