<template>
  <v-app class="dca-verify-web">
    <v-snackbar v-model="errorMsg" :timeout="2600" color="red">
      {{ $t('errorMsg') }}
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="errorMsg = null">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <LoadingScreen :loading="isLoading" />

    <v-container fluid class="pa-0"> <router-view></router-view> </v-container
  ></v-app>
</template>

<script>
// import { SIGNIN, SIGNUP } from "./router/routes";

import LoadingScreen from './components/LoadingScreen';

const LANG_KEY = 'language';

export default {
  name: 'App',

  created() {
    this.debug(`*** ${this.$options.name} ***`);

    this.initLang();
    this.initLogger();
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(newValue) {
        return this.$store.commit('isLoading', newValue);
      },
    },
    errorMsg: {
      get() {
        return this.$store.state.errorMsg;
      },
      set(newValue) {
        if (!newValue) {
          return this.$store.commit('clearError', newValue);
        }
      },
    },
  },
  components: {
    LoadingScreen,
  },
  methods: {
    initLogger() {
      this.$store.commit('initLogger', this.debug);
    },
    initLang() {
      let browserLang = navigator.language.substring(0, 2);
      let lang = localStorage.getItem(LANG_KEY) || browserLang;

      var check = this.langs.filter(function (elm) {
        if (elm.id == lang) {
          return elm.id; // returns length = 1 (object exists in array)
        }
        // return self.$i18n.locale;
      });
      this.$i18n.locale = check.length > 0 ? check[0].id : 'en';
    },
  },
  data() {
    return {
      langs: [
        { id: 'pt', name: 'Português' },
        { id: 'en', name: 'English' },
        { id: 'es', name: 'Español' },
      ],
    };
  },
};
</script>
