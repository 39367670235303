import {
  CREATE_TEMPLATE,
  TEMPLATE_ITEM,
  TEMPLATE_SYNC,
  TEMPLATE_EDITOR_CREATE,
  TEMPLATE_ITEM_EDITOR,
  UPLOAD_FILE,
} from '../../store/actions';

import { getFileFromUrl } from '../../plugins/snapshot';
function extractAttributes(components, layout) {
  let arr = [];

  components.forEach((item) => {
    if (item.type == 'text' || (item.dynamicImage && item.text === '[IMAGE]')) {
      let attrs = item.text.match(/\[[A-Z0-9 ]*\]/g);

      if (attrs && attrs.length > 0) {
        for (let index = 0; index < attrs.length; index++) {
          const el = attrs[index];
          let attrWithoutBrackets = el.replace(/\[|\]/g, '');
          var newItem = {
            attr: attrWithoutBrackets,
            type: item.type,
            // value: item.value,
            isPublic:
              attrWithoutBrackets.toLowerCase() == 'nome' ||
              attrWithoutBrackets.toLowerCase() == 'name' ||
              attrWithoutBrackets.toLowerCase() == 'nombre' ||
              (attrWithoutBrackets.toLowerCase() == 'username' &&
                layout === 'NFT'), //this.publicAttribute,
            isMandatory: item.isMandatory, // adicionar var para marcar se é obrigatorio
            order: item.templateSide == 'front' ? 0 : 1,
          };
          arr.push(newItem);
        }
      }
    }
  });
  return arr;
}

const initialState = {
  isPrinting: false,
  /* old store */
  currentLayout: null,
  layoutBackgroundColor: '#29969E',
  components: [],
  componentIDCount: 1,
  activeComponent: '',
  hasDynamicAttributes: false,

  selectedTab: 'Templates',
  uploadedImages: [],
  uploadedSignatures: [],
  backSide: false,
  currentSide: 'front',
  renderedDimensions: 0,
  activeAttribute: '',
  attributes: [] /* dynamic text placeholders is a better name */,
  template: {
    name: '',
    currentLayout: null,
    repeatedAttributes: false,
    idFront: 1,
    components: [],
    backgroundFront: '',
    backgroundBack: '',
  },
};
const templateEditor = {
  state() {
    return {
      isPrinting: false,
      /* old store */
      currentLayout: null,
      components: [],
      layoutBackgroundColor: '#29969E',

      componentIDCount: 1,
      activeComponent: '',
      hasDynamicAttributes: false,

      selectedTab: 'Templates',
      uploadedImages: [],
      uploadedSignatures: [],
      backSide: false,
      currentSide: 'front',
      renderedDimensions: 0,
      activeAttribute: '',
      attributes: [] /* dynamic text placeholders is a better name */,
      template: {
        name: '',
        repeatedAttributes: false,
        idFront: 1,
        components: [],
        backgroundFront: '',
        backgroundBack: '',
      },
      initialState: initialState,
    };
  },
  mutations: {
    isPrinting(state, value) {
      state.isPrinting = value;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
    changeLayout(state, layout) {
      state.template.currentLayout = layout;
      state.currentLayout = layout;
    },
    selectTab(state, tab) {
      state.selectedTab = tab;
    },
    setTemplate(state, preset) {
      state.template = preset;
    },
    enableBackSide(state, boolean) {
      state.backSide = boolean;
    },
    setCurrentSide(state, side) {
      state.currentSide = side;
    },
    setRenderedDimensions(state, dimension) {
      state.renderedDimensions = dimension;
    },
    setActiveAttribute(state, attribute) {
      state.activeAttribute = attribute;
    },
    setActiveComponent(state, component) {
      state.activeComponent = component;
    },
    setTemplateName(state, value) {
      state.template.name = value;
    },
    setRepeatedAttributes(state, value) {
      state.template.repeatedAttributes = value;
    },
    addUploadedImage(state, value) {
      state.uploadedImages.push(value);
    },
    addUploadedSignature(state, value) {
      state.uploadedSignatures.push(value);
    },
    incrementComponentIDCount(state) {
      state.componentIDCount++;
    },
    setHasDynamicAttributes(state, setting) {
      state.hasDynamicAttributes = setting;
    },
    updateLayoutBackgroundColor(state, value) {
      state.layoutBackgroundColor = value;
    },
    setComponents(state, values) {
      state.template.components = values;
      state.components = values;
    },
  },
  actions: {
    resetTemplate({ commit }) {
      commit('resetState');
    },
    changeLayout(context, layout) {
      context.commit('changeLayout', layout);
    },
    selectTab(context, tab) {
      context.commit('selectTab', tab);
    },
    setTemplate(context, preset) {
      console.log('setTemplate', preset.backgroundFront);
      context.commit('setTemplate', preset);
      context.commit('setComponents', preset.components);
      context.commit('changeLayout', preset.currentLayout);
    },
    enableBackSide(context, boolean) {
      context.commit('enableBackSide', boolean);
    },
    setCurrentSide(context, side) {
      context.commit('setCurrentSide', side);
    },
    setRenderedDimensions(context, dimension) {
      context.commit('setRenderedDimensions', dimension);
    },
    setActiveAttribute(context, attribute) {
      context.commit('setActiveAttribute', attribute);
    },
    setActiveComponent(context, component) {
      context.commit('setActiveComponent', component);
    },
    setHasDynamicAttributes(context, setting) {
      context.commit('setHasDynamicAttributes', setting);
      return setting;
    },

    [TEMPLATE_EDITOR_CREATE]: ({ dispatch, state }, { preview }) => {
      console.log('CREATE_TEMPLATE', CREATE_TEMPLATE, state.template);

      try {
        return new Promise((resolve, reject) => {
          if (
            !state.template.backgroundFront.includes(
              'files/uploaded/backgrounds'
            )
          ) {
            resolve(
              getFileFromUrl(
                state.template.backgroundFront,
                'backgroundFront'
              ).then((file) => {
                return dispatch(UPLOAD_FILE, {
                  file,
                  folder: 'backgrounds',
                }).catch((err) => {
                  console.error(err);
                  reject(err);
                });
              })
            );
          } else {
            resolve(state.template.backgroundFront);
          }
        })
          .then((url) => {
            console.log(url);
            state.template.backgroundFront = url;
            console.log(state.template.backgroundFront);

            return dispatch(CREATE_TEMPLATE, {
              name: state.template.name,
              frontend_props: {
                ...state.template,
                ...{ customTemplateName: 'templateEditor', preview },
              },
            });
          })
          .then((data) => {
            // let data = { tid: 1 };
            console.log(data);
            return dispatch('TEMPLATE_ITEM_EDITOR', { tid: data.tid });
          })
          .catch((err) => {
            throw err;
          });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [TEMPLATE_ITEM_EDITOR]: ({ rootState, state, dispatch }, { tid }) => {
      rootState.debug('TEMPLATE_ITEM');
      return new Promise((resolve, reject) => {
        let attrs = extractAttributes(
          state.template.components,
          state.currentLayout
        );
        let tables = [];
        //TO DO: possibilidade de adicionar mais tabelas

        // if (backTemplate && this.backTemplate.length > 0) {
        //   tables.push(this.createTable(this.backTemplate));
        // }

        console.log('tid', tid);
        console.log('TEMPLATE_ITEM', TEMPLATE_ITEM);
        console.log('TEMPLATE_SYNC', TEMPLATE_SYNC);
        console.log('attrs', attrs);
        dispatch(TEMPLATE_ITEM, {
          tid,
          attrs,
          tables,
        })
          .then((res) => {
            console.log(res);
            let tid = res.data.data.template.tid;
            dispatch(TEMPLATE_SYNC, res.data.data)
              .then(() => {
                resolve({ tid, name: '' });
              })
              .catch((err) => {
                console.error(err);
                reject(err);
              });
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
  },
  getters: {
    isPrinting(state) {
      return state.isPrinting;
    },
    currentLayout(state) {
      return state.currentLayout;
    },
    layoutBackgroundColor(state) {
      return state.layoutBackgroundColor;
    },
    selectedTab(state) {
      return state.selectedTab;
    },
    template(state) {
      return state.template;
    },
    currentSide(state) {
      return state.currentSide;
    },
    backSide(state) {
      return state.backSide;
    },
    renderedDimensions(state) {
      return state.renderedDimensions;
    },
    activeAttribute(state) {
      return state.activeAttribute;
    },
    activeComponent(state) {
      return state.activeComponent;
    },
    fontSize(state) {
      if (state.activeAttribute) {
        return state.activeAttribute.fontSize;
      } else {
        return null;
      }
    },
    templateName(state) {
      return state.template.name;
    },
    uploadedImages(state) {
      return state.uploadedImages;
    },
    uploadedSignatures(state) {
      return state.uploadedSignatures;
    },
    componentIDCount(state) {
      return state.template.components.length;
    },
    hasDynamicAttributes(state) {
      return state.template.components.some((item) =>
        item.text?.match(/\[[A-Z0-9 ]*\]/g)
      );
    },
    components(state) {
      return state.components;
    },
  },
};

export default templateEditor;
