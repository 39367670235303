import { ACCEPT, PROFILE, SIGN_IN, SIGN_OUT, WALLID_CONNECT } from '../actions';

import router from '../../router';

export const WALLID_ADDRESS = 'wallet_address';

const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_LOGOUT = 'AUTH_LOGOUT';
const WALLID_ONBOARDING = 'wallid_onboarding';

//Plugin errors

const ERR_NO_PERMISSION = 'ERR_NO_PERMISSION';
const ERR_PLUGIN_LOCKED = 'ERR_PLUGIN_LOCKED';

const state = {
  walletAddress: sessionStorage.getItem('user-token') || '',
  isInstalled: false,
};

const mutations = {
  AUTH_SUCCESS: (state, value) => {
    state.walletAddress = value;
  },
  AUTH_LOGOUT: (state) => {
    state.walletAddress = null;
  },
  setIsInstalled: (state, value) => {
    state.isInstalled = value;
  },
};

const actions = {
  initPlugin: ({ commit, dispatch, state }) => {
    console.log('Action initPlugin');

    const { wallid } = window;
    commit('setIsInstalled', Boolean(wallid));

    if (state.isInstalled) {
      dispatch('checkAccount');
    } else {
      // Assume that the plugin is not instaled
      document.addEventListener('wallid_event_ready', () => {
        console.log('Add events to initialize plugin');
        dispatch('checkAccount');
      });
    }
  },
  checkAccount: async ({ commit, dispatch }) => {
    //Initialize plugin
    try {
      console.log('activate Wallid Plugin');
      console.log('Has Wallid plugin, starting listeners');

      document.addEventListener('wallid_event_lock', () => {
        console.log('wallid_event_lock');
        dispatch(SIGN_OUT);
      });

      document.addEventListener('wallid_event_unlock', () => {
        console.log('wallid_event_unlock');
        dispatch(WALLID_ADDRESS);
        // .then(() => router.push('/Dashboard')); // causes a problem with signup
      });

      document.addEventListener('wallid_wallet_connected', () => {
        console.log('wallid_wallet_connected');

        sessionStorage.setItem('WALLET_CREATED', 'true');
        dispatch(WALLID_ADDRESS)
          .then(() => {
            console.log('dispatch ACCEPT');
            dispatch(ACCEPT);
            console.log('dispatch', PROFILE);
          })
          .then(() => {
            dispatch(PROFILE);
          })
          .then(() => router.push('/Dashboard'));
      });

      //Try get wallet address
      await dispatch(WALLID_ADDRESS);
    } catch (err) {
      console.error(err);
    }
    commit('isLoading', false);
  },

  [WALLID_ADDRESS]: ({ commit }) => {
    console.log('Action WALLID_ADDRESS');

    // Never throws exception
    return new Promise((resolve) => {
      try {
        window
          .wallid('wallet_address')
          .then((walletAddress) => {
            console.log('Wallet plugin store', walletAddress);

            commit(AUTH_SUCCESS, walletAddress);
            console.log(router.currentRoute.path);

            // Required to detect manual unlock in Sign In page
            if (router.currentRoute.path == '/SignIn' && walletAddress) {
              router.push('/Dashboard');
            }
            sessionStorage.setItem('user-token', walletAddress); // store the token in sessionStorage
            resolve(walletAddress);
          })
          .catch((err) => {
            console.log(err);
            resolve(err);
          });
        // you have your token, now log in your user :)
      } catch (err) {
        console.log(err);
        sessionStorage.removeItem('user-token');
        resolve(err);
      }
    });
  },

  [WALLID_ONBOARDING]: ({ rootState }) => {
    rootState.debug('Action CheckOnboarding');
    return new Promise((resolve, reject) => {
      try {
        console.log('force plugin call');
        window.wallid(WALLID_ONBOARDING).then((res) => {
          console.log('completed', res.initialized);
          if (res.initialized) {
            sessionStorage.setItem('WALLET_CREATED', 'true');
            localStorage.setItem('REGISTRATION_IN_PROGRESS', 'false');
          }
          resolve(res.initialized);
        });
      } catch (err) {
        reject(err);
      }
    });
  },
  [SIGN_OUT]: ({ commit, rootState }) => {
    rootState.debug('Action SIGN_OUT');
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT);
      sessionStorage.removeItem('user-token'); // clear your user's token from sessionStorage
      resolve(
        router.push({
          path: '/SignIn',
        })
      );
    });
  },

  [WALLID_CONNECT]: ({ rootState }) => {
    console.log('Action WALLID_CONNECT');
    return new Promise((resolve, reject) => {
      try {
        window
          .wallid('wallid_connect', { level: 2 })
          .then((account) => {
            rootState.debug('Wallet wallid_connect', account);
            rootState.debug('invite', localStorage.getItem('invite'));
            resolve(true);
          })
          .catch((err) => {
            sessionStorage.removeItem('user-token'); // if the request fails, remove any possible user token if possible
            reject(err);
          });
      } catch (err) {
        rootState.debug(err);
        sessionStorage.removeItem('user-token'); // if the request fails, remove any possible user token if possible
        reject(err);
      }
    });
  },
  [SIGN_IN]: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(WALLID_ADDRESS).then((res) => {
          rootState.debug('SIGN_IN', res);

          if (
            res instanceof Error ||
            res == ERR_NO_PERMISSION ||
            res == ERR_PLUGIN_LOCKED
          ) {
            // dispatch(SIGN_OUT);
            dispatch(WALLID_CONNECT)
              .then(() => {
                dispatch(WALLID_ADDRESS).then((res) => {
                  resolve(res);
                });
              })
              .catch((err) => {
                reject(err);
              });
            // sessionStorage.setItem("user-token", account); // store the token in sessionStorage
            // commit(AUTH_SUCCESS, account);
            // you have your token, now log in your user :)
          } else {
            commit('isLoading', false);
            resolve(res);
          }
        });
        // });
      } catch (err) {
        rootState.debug('SIGN IN error: ', err);
        sessionStorage.removeItem('user-token'); // if the request fails, remove any possible user token if possible
        reject(err);
      }
    });
  },
};
const getters = {
  walletAddress: (state) => state.walletAddress,
  isUnlocked: (state) => !!state.walletAddress,
};

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
