import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
Vue.use(VueRouter);

import store from '../store';
import util from '../plugins/util';

import i18n from '../i18n';

const debug = util.methods.debug;
// function inIframe() {
//   try {
//     return window.self !== window.top;
//   } catch (e) {
//     return true;
//   }
// }
const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach((to, from, next) => {
  // const isWalletCreated = sessionStorage.getItem('WALLET_CREATED') == 'true';
  // const isPluginInstalled = localStorage.getItem('PLUGIN_INSTALLED') == 'true';
  // const isRegistering =
  //   localStorage.getItem('REGISTRATION_IN_PROGRESS') == 'true';

  // // debug('isWalletCreated', isWalletCreated);
  // // debug('isPluginInstalled', isPluginInstalled);
  // // debug('isRegistering', isRegistering);

  debug('toPath', to);
  debug('isUnlocked', store.getters.isUnlocked);

  if (to.meta.i18n) {
    Object.keys(to.meta.i18n).forEach((lang) =>
      i18n.mergeLocaleMessage(lang, to.meta.i18n[lang])
    );
  }

  var invite = to.query.invite_admin;

  if (invite) {
    localStorage.setItem('invite_admin', invite);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isUnlocked) {
      next();
      return;
    }
    next('/SignIn');
  } else {
    if (to.path == '/') {
      debug('Home Path');
      return next('/Dashboard');
    } else {
      return next();
    }
  }

  // if (to.path == "/SignIn" || to.path == "/SignUp") {
  //   debug("Login/Create");
  //   return next();
  // }
  // if (inIframe()) {
  //   console.log("In Iframe");

  //   return next({ path: "/SignIn" });
  // }
  // if (isWalletCreated && !isUnlocked && to.path != "/SignIn") {
  //   debug("SignIn");
  //   return next({ path: SIGNIN });
  // }

  // if (isRegistering && to.path != "/SignUp") {
  //   console.log("isRegistering Path");
  //   return next({ path: "/SignUp" });
  // }
  // if (
  //   !isWalletCreated &&
  //   !isUnlocked &&
  //   !isPluginInstalled &&
  //   to.path != "/SignIn"
  // ) {
  //   debug("SignIn");
  //   return next({ path: SIGNIN });
  // }

  // if (!isWalletCreated && isPluginInstalled && to.path != "/SignUp") {
  //   debug("SignUp");
  //   return next({ path: SIGNUP });
  // }
  // if (to.path == "/") {
  //   debug("Home Path");
  //   return next("/Dashboard");
  // } else {
  //   return next();
  // }
});

export default router;
